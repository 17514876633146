import React, { useState, useEffect, useRef, useCallback } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { FormParams, LidarSpec } from "../Types/Types";
import ToolTip from "../Shared/ToolTip";

interface Props {
  formObj: FormParams;
  update: (arg1: string, arg2: boolean) => void;
  lidar_spec: LidarSpec[];
}

const LidarCollectionCalibrationClassification = ({
  update,
  formObj,
  lidar_spec,
}: Props) => {
  const handleChange = (e) => {
    update(e.target.name, e.target.value);
  };

  const renderAccuracy = (label) => {
    if (formObj.lidar_co_ca_cl_label) {
      const sel = lidar_spec.find((record) => record.name === label);

      switch (formObj.lidar_co_ca_cl_label) {
        case "4 PPSM":
          return `(${sel.ppsm_4})`;
        case "8 PPSM":
          return `(${sel.ppsm_8})`;
        case "20 PPSM":
          return `(${sel.ppsm_20})`;
        case "30 PPSM":
          return `(${sel.ppsm_30})`;
        case "40 PPSM":
          return `(${sel.ppsm_40})`;
        case "50 PPSM":
          return `(${sel.ppsm_50})`;
        case "60 PPSM":
          return `(${sel.ppsm_60})`;
        case "250 PPSM":
          return `(${sel.ppsm_250})`;
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  return (
    <div style={{ padding: "0.5em 0.25em 0.5em 0.5em" }}>
      <Form.Label htmlFor="lidar_co_ca_cl_label">Density</Form.Label>
      <Form.Select
        id="lidar_co_ca_cl_label"
        name="lidar_co_ca_cl_label"
        defaultValue={formObj.lidar_co_ca_cl_label}
        onChange={handleChange}
      >
        <option></option>
        <option value="4 PPSM">4 PPSM</option>
        <option value="8 PPSM">8 PPSM</option>
        <option value="20 PPSM">20 PPSM</option>
        <option value="30 PPSM">30 PPSM</option>
        <option value="40 PPSM">40 PPSM</option>
        <option value="50 PPSM">50 PPSM</option>
        <option value="60 PPSM">60 PPSM</option>
        <option value="250 PPSM">250 PPSM</option>
      </Form.Select>

      <Form.Label htmlFor="lidar_co_ca_cl_accuracy_label">Accuracy</Form.Label>

      <ToolTip
        style={{ float: "right", marginTop: "3px" }}
        title="Accuracy"
        message="Hard surface RMSE Accuracy, produced or tested to meet depending on how many ground control targets are available."
      />
      <Form.Select
        id="lidar_co_ca_cl_accuracy_label"
        name="lidar_co_ca_cl_accuracy_label"
        defaultValue={formObj.lidar_co_ca_cl_accuracy_label}
        onChange={handleChange}
      >
        <option></option>
        <option value="Good">Good {renderAccuracy("Good")}</option>
        <option value="High">High {renderAccuracy("High")}</option>
        <option value="Highest">Highest {renderAccuracy("Highest")}</option>
      </Form.Select>
    </div>
  );
};

export default LidarCollectionCalibrationClassification;
