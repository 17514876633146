import React, { useState, useEffect, useRef, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { FormParams } from "../Types/Types";

import ToolTip from "../Shared/ToolTip";

interface Props {
  formObj: FormParams;
  update: (arg1: string, arg2: boolean) => void;
}

const LidarAdditionalDeliverables = ({ update, formObj}: Props) => {
  const handleChange = (e) => {
    update(e.target.name, e.target.checked ? true : false);
  };

  return (
    <div style={{ padding: "0.5em 0.25em 0.5em 0.5em" }}>
      <div className="flex">
        <Form.Check
          type={"checkbox"}
          id="lad_ground_classified_dem"
          name="lad_ground_classified_dem"
          label={"Ground Classified DEM"}
          defaultChecked={formObj.lad_ground_classified_dem}
          onChange={handleChange}
          className="flex-grow"
        />
        <ToolTip
          title="Ground Classified DEM"
          message="Delivered in raster format as a GeoTiff, this is a map of the ground elevation in a grid."
        />
      </div>

      <div className="flex">
        <Form.Check
          type={"checkbox"}
          id="lad_plan_ref_contours"
          name="lad_plan_ref_contours"
          label={"Reference Contours"}
          defaultChecked={formObj.lad_plan_ref_contours}
          onChange={handleChange}
          className="flex-grow"
        />
        <ToolTip
          title="Reference Contours"
          message="Delivered in DWG or DXF, these 3D lines represent an elevation reference."
        />
      </div>
      <div className="flex">
        <Form.Check
          type={"checkbox"}
          id="lad_plan_map_offshore"
          name="lad_plan_map_offshore"
          label={"Planimetric Mapping (offshore)"}
          defaultChecked={formObj.lad_plan_map_offshore}
          onChange={handleChange}
          className="flex-grow"
        />
        <ToolTip
          title="Planimetric Mapping (offshore)"
          message="Delivered in DWG or DXF, this linework represents existing features on the ground. This price is not representative of dense urban areas"
        />
      </div>
      <div className="flex">
        <Form.Check
          type={"checkbox"}
          id="lad_plan_map_onshore"
          name="lad_plan_map_onshore"
          label={"Planimetric Mapping (onshore)"}
          defaultChecked={formObj.lad_plan_map_onshore}
          onChange={handleChange}
          className="flex-grow"
        />
        <ToolTip
          title="Planimetric Mapping (onshore)"
          message="Delivered in DWG or DXF, this linework represents existing features on the ground. This price is not representative of dense urban areas"
        />
      </div>
    </div>
  );
};

export default LidarAdditionalDeliverables;
