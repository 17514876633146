import React, { useState, useEffect, useRef, useCallback } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { FormParams } from "../Types/Types";

interface Props {
  formObj: FormParams;
    update: (arg1: string, arg2: boolean) => void;
  }

const ScheduleRestrictions = ({ formObj, update }: Props) => {
    const handleChange = (e) => {
        update(e.target.name, e.target.value);
      };

  return (
    <div style={{ padding: "0.5em 0.25em 0.5em 0.5em" }}>
      <Form.Check
        type={"radio"}
        id="4_8_weeks"
        name="sched_restrict_label"
        label={"4-8+ week flexibility, flexible delivery"}
        value={"4-8+ week flexibility, flexible delivery"}
        onChange={handleChange}
        defaultChecked={formObj.sched_restrict_label === "4-8+ week flexibility, flexible delivery"}
      />
      <Form.Check
        type={"radio"}
        id="2_4_weeks"
        name="sched_restrict_label"
        label={"2-4 week flexibility, normal delivery"}
        value={"2-4 week flexibility, normal delivery"}
        onChange={handleChange}
        defaultChecked={formObj.sched_restrict_label === "2-4 week flexibility, normal delivery"}
      />
      <Form.Check
        type={"radio"}
        id="within_2_weeks"
        name="sched_restrict_label"
        label={"Collect within 2 weeks, normal delivery"}
        value={"Collect within 2 weeks, normal delivery"}
        onChange={handleChange}
        defaultChecked={formObj.sched_restrict_label === "Collect within 2 weeks, normal delivery" || !formObj.sched_restrict_label}
      />
      <Form.Check
        type={"radio"}
        id="within_3_days"
        name="sched_restrict_label"
        label={"Collect within 3 days, expedited delivery"}
        value={"Collect within 3 days, expedited delivery"}
        onChange={handleChange}
        defaultChecked={formObj.sched_restrict_label === "Collect within 3 days, expedited delivery"}
      />
      <Form.Check
        type={"radio"}
        id="col_and_deliver"
        name="sched_restrict_label"
        label={"Collect & deliver as soon as possible"}
        value={"Collect & deliver as soon as possible"}
        onChange={handleChange}
        defaultChecked={formObj.sched_restrict_label === "Collect & deliver as soon as possible"}
      />
    </div>
  )
}

export default ScheduleRestrictions