import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { FormParams } from "../Types/Types";

import ToolTip from "../Shared/ToolTip";

interface Props {
  formObj: FormParams;
  update: (arg1: string, arg2: boolean | number) => void;
}

const Other = ({ update, formObj }: Props) => {
  const [renderControlPoints, setRenderControlPoints] = useState(formObj.other_control_survey ? true : false);

  const handleChange = (e) => {
    // console.error({e})

    // if other_control_survey is selected
    if (e.target.name === "other_control_survey") {
      if (e.target.checked === true) {
        setRenderControlPoints(true);
      } else {
        setRenderControlPoints(false);
        // update("other_control_survey", false);
        update("other_control_survey_points", null);
      }
    }

    update(e.target.name, e.target.checked ? true : false);
  };

  const inputChange = (e) => {
    if (e.target.value > 0) {
      update("other_control_survey_points", parseInt(e.target.value));
    } else {
      update("other_control_survey_points", null);
    }
  };

  return (
    <div style={{ padding: "0.5em 0.25em 0.5em 0.5em" }}>
      {/* <Form.Check
        type={"checkbox"}
        defaultChecked={formObj.other_quote_valid_60_days}
        id="other_quote_valid_60_days"
        name="other_quote_valid_60_days"
        label={"Quote Valid for 60 Days"}
        onChange={handleChange}
      />
      <Form.Check
        type={"checkbox"}
        defaultChecked={formObj.other_quote_valid_180_days}
        id="other_quote_valid_180_days"
        name="other_quote_valid_180_days"
        label={"Quote Valid for 180 Days"}
        onChange={handleChange}
      /> */}
      <div className="flex">
        <Form.Check
          type={"checkbox"}
          defaultChecked={formObj.other_control_statistics_report}
          id="other_control_statistics_report"
          name="other_control_statistics_report"
          label={"Control Statistics Report"}
          onChange={handleChange}
          className="flex-grow"
        />
        <ToolTip
          title="Control Statistics Report"
          message="This is an ASCII .TXT file with the numbers and statistics of accuracy and errors at control and check points."
        />
      </div>
      <div className="flex">
        <Form.Check
          type={"checkbox"}
          defaultChecked={formObj.other_processing_report}
          id="other_processing_report"
          name="other_processing_report"
          label={"Processing Report (Per Type - Imagery & LiDAR)"}
          onChange={handleChange}
          className="flex-grow"
        />
        <ToolTip
          title="Processing Report (Per Type - Imagery & LiDAR)"
          message="This is a PDF including processing details including graphics. See a sample at <hyperlink >"
        />
      </div>
      <div className="flex">
        <Form.Check
          type={"checkbox"}
          defaultChecked={formObj.other_need_flight_plan}
          id="other_need_flight_plan"
          name="other_need_flight_plan"
          label={"Need Flight Plan/Layout finalized now"}
          onChange={handleChange}
          className="flex-grow"
        />
        <ToolTip
          title="Need Flight Plan/Layout finalized now"
          message="Within 1 business day, your flight plan will be delivered in KML format showing flight lines and planned coverage."
        />
      </div>
      <div className="flex">
        <Form.Check
          type={"checkbox"}
          defaultChecked={formObj.other_special_airspace}
          id="other_special_airspace"
          name="other_special_airspace"
          label={"Special Airspace"}
          onChange={handleChange}
          className="flex-grow"
        />
        <ToolTip
          title="Special Airspace"
          message="Is your project within 5 miles of a major airport or restricted airspace?"
        />
      </div>
      <div className="flex">
        <Form.Check
          type={"checkbox"}
          defaultChecked={formObj.other_control_survey}
          id="other_control_survey"
          name="other_control_survey"
          label={"Control Survey"}
          onChange={handleChange}
          className="flex-grow"
        />
        <ToolTip
          title="Control Survey"
          message="If you know how many control & check points your project needs, enter it here. Otherwise set to 0 to have ASI custom quote this after receiving your price for our other services."
        />
      </div>
      {renderControlPoints && (
        <>
          <Form.Label htmlFor="other_control_survey_points">
            Number of Control Survey Points
          </Form.Label>
          <Form.Control
            type="number"
            defaultValue={formObj.other_control_survey_points || 0}
            id="other_control_survey_points"
            name="other_control_survey_points"
            step="1"
            onChange={inputChange}
          />
        </>
      )}
      <div className="flex">
        <Form.Check
          type={"checkbox"}
          defaultChecked={formObj.other_mountainous_terrain}
          id="other_mountainous_terrain"
          name="other_mountainous_terrain"
          label={"Mountainous Terrain"}
          onChange={handleChange}
          className="flex-grow"
        />
        <ToolTip
          title="Mountainous Terrain"
          message="Does your project contain large terrain gradients with more than 1,000ft of elevation change in less than 3 miles?"
        />
      </div>
      {/* <Form.Check
        type={"checkbox"}
        defaultChecked={formObj.other_payment_card_paypal_venmo}
        id="other_payment_card_paypal_venmo"
        name="other_payment_card_paypal_venmo"
        label={"Payment via card, Paypal, Venmo, etc."}
        onChange={handleChange}
      /> */}
      <div className="flex">
        <Form.Check
          type={"checkbox"}
          defaultChecked={formObj.other_first_airborne_lidar}
          id="other_first_airborne_lidar"
          name="other_first_airborne_lidar"
          label={"First Airborne LiDAR project"}
          onChange={handleChange}
          className="flex-grow"
        />
        <ToolTip
          title="First Airborne LiDAR project"
          message="This is my first manned LiDAR or Imagery project."
        />
      </div>
      <Form.Check
        type={"checkbox"}
        defaultChecked={formObj.other_only_client}
        id="other_only_client"
        name="other_only_client"
        label={"This data is only for use by Client, no release"}
        onChange={handleChange}
      />
    </div>
  );
};

export default Other;
