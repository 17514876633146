import React, { useState, useEffect, useRef, useCallback } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import { useDebounceCallback, useResizeObserver } from "usehooks-ts";

// Form Components
import QAQC from "../FormComponents/QAQC";
import LidarCollectionCalibrationClassification from "../FormComponents/LidarCollectionCalibrationClassification";
import LidarAdditionalDeliverables from "../FormComponents/LidarAdditionalDeliverables";
import ImageryCollectionOrthorectified from "../FormComponents/ImageryCollectionOrthorectified";
import ScheduleRestrictions from "../FormComponents/ScheduleRestrictions";
import Other from "../FormComponents/Other";
import { convex } from "@turf/turf";
import ToolTip from "../Shared/ToolTip";

// Types
import {
  FormParams,
  ImageryAccuracy,
  ImageryClass,
  LidarSpec,
} from "../Types/Types";
import { Col, Container, Row } from "react-bootstrap";

interface Response {
  token: string;
  message: string;
  pass: boolean;
  total: number;
}

interface Props {
  area: number;
  dist: number;
  aoi: any;
  token: string;
  setStep: (arg0: string) => void;
  // setResponse: (arg0: Response) => void;
  formObj: FormParams;
  setFormObj: (arg0: FormParams) => void;
  update: (arg0: string, arg1: boolean | string | number) => void;
  submit: () => void;
  lidar_spec: LidarSpec[];
  imagery_accuracies: ImageryAccuracy[];
  imagery_class: ImageryClass[];
}

const ProjectDetails = ({
  area,
  dist,
  aoi,
  token,
  setStep,
  // setResponse,
  formObj,
  setFormObj,
  update,
  submit,
  lidar_spec,
  imagery_accuracies,
  imagery_class,
}: Props) => {
  // Components
  // const [qaqc, setQaqc] = useState(false);
  // const [lidarCoCaCL, setLidarCoCaCL] = useState(false);
  // const [lidarAdditionalDeliverables, setLidarAdditionalDeliverables] =
  //   useState(false);
  // const [imageryCoOrth, setImageryCoOrth] = useState(false);
  // const [schedRestrict, setSchedRestrict] = useState(false);
  // const [other, setOther] = useState(false);
  // const $el = document.getElementById("map-container");

  // form management
  const [submitted, setSubmitted] = useState(false);
  const [message, setMessage] = useState<string | null>(null);

  // // Updates the Form Object
  // const update = (property: string, value: boolean | string | number) => {
  //   console.error("UPDATE", { property, value });

  //   let obj = { ...formObj };
  //   obj[property] = value;
  //   setFormObj(obj);
  // };

  useEffect(() => {
    console.warn({ formObj });
  }, [formObj]);

  useEffect(() => {
    let other_processing_report_count = 0;
    if (formObj.lidar_co_ca_cl_enabled) {
      other_processing_report_count += 1;
    }

    if (formObj.image_co_or_enabled) {
      other_processing_report_count += 1;
    }

    update("other_processing_report_count", other_processing_report_count);
  }, [formObj.lidar_co_ca_cl_enabled, formObj.image_co_or_enabled]);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setMessage(null);
      }, 3000);
    }
  }, [message]);

  useEffect(() => {
    if (formObj.hasOwnProperty("lidar_co_ca_cl_enabled") && formObj.lidar_co_ca_cl_enabled === false) {
      console.error("lidar_co_ca_cl_enabled useeffect");

      const obj = { ...formObj };

      obj.lidar_co_ca_cl_label = null;
      obj.lidar_co_ca_cl_accuracy_label = null;

      // LAD
      obj.lad_enabled = false;
      obj.lad_ground_classified_dem = null;
      obj.lad_plan_ref_contours = null;
      obj.lad_plan_map_offshore = null;
      obj.lad_plan_map_onshore = null;

      setFormObj(obj);
    }
  }, [formObj.lidar_co_ca_cl_enabled]);

  useEffect(() => {
    if (!formObj.image_co_or_enabled) {
      const obj = { ...formObj };

      obj.image_co_or_accuracy_label = null;
      obj.image_co_or_grade = null;
      obj.image_co_or_label = null;
      obj.image_co_or_sun_angle = null;

      setFormObj(obj);
    }
  }, [formObj.image_co_or_enabled]);

  const handleSubmit = () => {
    if (
      !formObj.client_name ||
      !formObj.project_name ||
      !formObj.phone_no ||
      !formObj.email
    ) {
      setStep("additional_info");
    } else {
      submit();
    }
  };

  // console.log({ formObj, aoi });

  return (
    <>
      <Offcanvas show={true}>
        <Offcanvas.Header
          className="resize-rm"
          style={{ display: "block", textAlign: "center" }}
        >
          <Offcanvas.Title style={{ fontSize: "14pt" }}>
            Project Details
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ padding: ".5em" }}>
          <div style={{ paddingTop: "0.5em" }}>
            {message && (
              <Alert key={"danger"} variant={"danger"}>
                {message}
              </Alert>
            )}

            {/* <Form.Check
              defaultChecked={formObj.qaqc_other_enabled}
              onChange={(e) => update("qaqc_other_enabled", e.target.checked)}
              type={"switch"}
              id="qaqc"
              label={"QA/QC (per area)"}
            />
            {formObj.qaqc_other_enabled && (
              <QAQC update={update} formObj={formObj} />
            )} */}
            <Form.Check
              defaultChecked={formObj.lidar_co_ca_cl_enabled}
              onChange={(e) =>
                update("lidar_co_ca_cl_enabled", e.target.checked)
              }
              type={"switch"}
              id="lidar_co_ca_cl"
              label={"LiDAR Collection, Calibration, Automated Classification"}
            />
            {formObj.lidar_co_ca_cl_enabled && (
              <LidarCollectionCalibrationClassification
                update={update}
                formObj={formObj}
                lidar_spec={lidar_spec}
              />
            )}
            {formObj.lidar_co_ca_cl_enabled && (
              <>
                <Form.Check
                  defaultChecked={formObj.lad_enabled}
                  onChange={(e) => update("lad_enabled", e.target.checked)}
                  type={"switch"}
                  id="lidar_add_deliverables"
                  label={"Lidar Additional Deliverables"}
                />
                {formObj.lad_enabled && (
                  <LidarAdditionalDeliverables
                    update={update}
                    formObj={formObj}
                  />
                )}
              </>
            )}

            <div className="flex">
              <Form.Check
                defaultChecked={formObj.image_co_or_enabled}
                onChange={(e) => {
                  if (e.target.checked) {
                    const obj = { ...formObj };

                    obj.image_co_or_enabled = true;
                    obj.image_co_or_label = "3 in";
                    obj.image_co_or_accuracy_label = "High";
                    obj.image_co_or_grade = "C";
                    obj.image_co_or_sun_angle = "20 Degrees";

                    setFormObj(obj);
                  } else {
                    update("image_co_or_enabled", false);
                  }
                }}
                type={"switch"}
                id="iamgery_collection_orthorectified"
                label={"Imagery Collection, orthorectified"}
                className="flex-grow"
              />
              <ToolTip
                title="Imagery Collection, orthorectified"
                message="The GSD indicates the spatial resolution or detail available in a map. This might be considered zoom level with smaller values indicating higher detail and more time/cost to collect"
              />
            </div>
            {formObj.image_co_or_enabled && (
              <ImageryCollectionOrthorectified
                update={update}
                formObj={formObj}
                imagery_accuracies={imagery_accuracies}
                imagery_class={imagery_class}
              />
            )}
            {/* <Form.Check
              defaultChecked={formObj.sched_restrict_enabled}
              onChange={(e) =>
                update("sched_restrict_enabled", e.target.checked)
              }
              // onChange={() => setSchedRestrict(!schedRestrict)}
              type={"switch"}
              id="sched_strict"
              label={"Schedule Restrictions"}
            />
            {formObj.sched_restrict_enabled && (
              <ScheduleRestrictions update={update} formObj={formObj} />
            )} */}
            <Form.Check
              defaultChecked={formObj.other_enabled}
              onChange={(e) => update("other_enabled", e.target.checked)}
              type={"switch"}
              id="7"
              label={"Other"}
            />
            {formObj.other_enabled && (
              <Other update={update} formObj={formObj} />
            )}
            <hr />

            <Container>
              <Row>
                <Col>
                  <Button
                    variant="secondary"
                    onClick={() => setStep("draw")}
                    style={{ width: "100%" }}
                  >
                    Update AOI
                  </Button>
                </Col>
                <Col>
                  <Button
                    onClick={() => handleSubmit()}
                    disabled={submitted}
                    style={{ width: "100%" }}
                  >
                    {submitted ? "Awaiting Response..." : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Container>

            {(formObj.client_name ||
              formObj.project_name ||
              formObj.phone_no ||
              formObj.email) && (
              <div className="d-grid gap-2">
                <Button
                  variant="secondary"
                  onClick={() => setStep("additional_info")}
                  style={{ margin: "0.75em" }}
                >
                  Update Additional Info
                </Button>
              </div>
            )}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ProjectDetails;
