import React, { useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";

interface Props {
  clientName: string;
  projectName: string;
  setClientName: (arg0: string) => void;
  setProjectName: (arg0: string) => void;
  setStep: (arg0: string) => void;
}

const WelcomeModal = ({
  clientName,
  projectName,
  setClientName,
  setProjectName,
  setStep,
}: Props) => {
  return (
    <Modal centered show={true} backdrop={"static"} keyboard={false}>
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Title>Welcome to Aerial Services, Inc.</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ textAlign: "center" }}>
          <p>Let us price your project today!</p>
        </div>

        {/* <div style={{ width: "80%", margin: "0 auto" }}>
          <Form.Label htmlFor="client_name">Client Name</Form.Label>
          <Form.Control
            type="text"
            value={clientName}
            placeholder="Your Business Name"
            id="client_name"
            onChange={(e) => setClientName(e.target.value)}
            autoComplete="off"
          />
          <Form.Label htmlFor="project_name">Project Name</Form.Label>
          <Form.Control
            type="text"
            id="project_name"
            value={projectName}
            placeholder="Name of Estimate"
            onChange={(e) => setProjectName(e.target.value)}
            autoComplete="off"
          />
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => setStep("aoi")}
          // disabled={projectName.length === 0 || clientName.length === 0}
        >
          Get Started
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WelcomeModal;
