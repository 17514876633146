import React, { useRef } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import * as tj from "@mapbox/togeojson";
import rewind from "@mapbox/geojson-rewind";

interface Props {
  setStep: (arg0: string) => void;
  setKmlAOI: (arg0: string) => void;
  // setDraw: (arg0: boolean) => void;
}

const AOIModal = ({ setStep, setKmlAOI }: Props) => {
  const kmlFileRef = useRef(null);

  const onFilechange = (event) => {
    const file = event.target.files[0]; // get file
    const ext = getFileExtension(file);
    const reader = new FileReader();
    const size = file.size;

    if (size > 5000000) {
      alert("Maximum KML file size is 5 Megabytes");
      return false;
    }

    // on load file end, parse the text read
    reader.onloadend = (event) => {
      var text = event.target.result;
      if (ext === "kml") {
        parseTextAsKml(text);
      }
    };

    reader.readAsText(file); // start reading file
  };

  const parseTextAsKml = (text) => {
    const dom = new DOMParser().parseFromString(text, "text/xml"); // create xml dom object
    const converted = tj.kml(dom); // convert xml dom to geojson
    rewind(converted, false); // correct right hand rule
    // console.log({converted});
    setKmlAOI(converted); // save converted geojson to hook state
    setStep("draw");
  };

  const getFileExtension = (file) => {
    const name = file.name;
    const lastDot = name.lastIndexOf(".");
    return name.substring(lastDot + 1);
  };

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    kmlFileRef.current.click();
  };

  return (
    <Modal
      centered
      show={true}
      animation={false}
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Title>Define your Area of Interest (AOI)</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <Container>
          <Row>
            <Col>
              <h6>Upload KML</h6>
              <input
                style={{ display: "none" }}
                ref={kmlFileRef}
                type="file"
                id="kml_file"
                name="kml"
                accept=".kml"
                onChange={onFilechange}
              />
              <Button onClick={() => onBtnClick()}>Import</Button>
            </Col>
            <Col>
              <h6>Draw Area on Map</h6>
              <Button onClick={() => setStep("draw")}>Draw</Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default AOIModal;
