import React from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { Message } from "../Types/Types";

interface Props {
  message: Message;
  setMessage: (arg1: Message) => void;
  setStep: (arg1: string) => void;
}

const ErrorModal = ({ message, setMessage, setStep }: Props) => {
  return (
    <Modal
      centered
      show={true}
      animation={false}
      backdrop={"static"}
      keyboard={false}
    >
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Title>Errors Detected!</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <Container>
          <p>{message.message}</p>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        {message.type === "ProjectDetails" && (
          <Button
            variant="secondary"
            onClick={() => {
              setStep("project_details");
              setMessage(null);
            }}
          >
            View Project Details
          </Button>
        )}
        {message.type === "QuoteDetails" && (
          <Button
            variant="primary"
            onClick={() => {
              setStep("additional_info");
              setMessage(null);
            }}
          >
            View Quote Details
          </Button>
        )}
        {message.type === "Draw" && (
        <Button
          variant="primary"
          onClick={() => {
            setStep("draw");
            setMessage(null);
          }}
        >
          Add AOI
        </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
