import React, { useState, useEffect, useRef, useCallback } from "react";
import Form from "react-bootstrap/Form";
import { FormParams } from "../Types/Types";

interface Props {
  formObj: FormParams;
  update: (arg1: string, arg2: boolean) => void;
}

const QAQC = ({ update, formObj }: Props) => {

  const handleChange = (e) => {
    update(e.target.name, e.target.checked ? true : false);
  }

  return (
    <div style={{ padding: "0.5em 0.25em 0.5em 0.5em" }}>
      <Form.Check
        type={"checkbox"}
        defaultChecked={formObj.qaqc_other_project_setup}
        id="qaqc_other_project_setup"
        name="qaqc_other_project_setup"
        label={"Other Project Setup"}
        onChange={handleChange}
      />
      {/* <Form.Check
        type={"checkbox"}
        id="qaqc_10cm_rmsev"
        name="qaqc_10cm_rmsev"
        label={"10cm RMSEv, Class III orthophoto"}
        onChange={handleChange}
      />
      <Form.Check
        type={"checkbox"}
        id="qaqc_5cm_rmsev"
        name="qaqc_5cm_rmsev"
        label={"5cm RMSEv, Class II orthophoto"}
        onChange={handleChange}
      />
      <Form.Check
        type={"checkbox"}
        id="qaqc_3cm_rmsev"
        name="qaqc_3cm_rmsev"
        label={"BEST 3cm RMSEv, Class I orthophotos"}
        onChange={handleChange}
      /> */}
    </div>
  );
};

export default QAQC;
