import React, { useState, useRef, useEffect } from "react";
import { FeatureGroup, GeoJSON } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { Button, Col, Container, Row } from "react-bootstrap";
import L from "leaflet";
import { rewind } from "@turf/turf";
import * as tj from "@mapbox/togeojson";

import "leaflet-draw/dist/leaflet.draw.css";

interface Props {
  step: string;
  kmlAOI: any;
  setKmlAOI: (arg0: any) => void;
  setMapFeatures: (arg0: any) => void;
}

const EditFeature = ({ step, setKmlAOI, kmlAOI, setMapFeatures }: Props) => {
  const featureGroupRef = useRef(null);
  const kmlFileRef = useRef(null);

  const [active, setActive] = useState(false);
  const [features, setFeatures] = useState([]);
  const [layer, setLayer] = useState<any>(null);
  const [drawControl, setDrawControl] = useState(null);

  useEffect(() => {
    if (kmlAOI) {
      console.error("kmlAOI", { featureGroupRef, layer });

      // remove all layers from the map
      featureGroupRef.current.clearLayers();

      // create a new geojson layer
      const leafletGeoJSON = new L.GeoJSON(kmlAOI);

      // add the layers to the feature group
      leafletGeoJSON.eachLayer((layer) => {
        featureGroupRef.current.addLayer(layer);
      });

      featureGroupRef.current._map.fitBounds(
        featureGroupRef.current.getBounds()
      );

      setLayer(leafletGeoJSON);

      setFeatures(
        kmlAOI.features.map((layer) => {
          layer.properties.id = generateToken();
          return layer;
        })
      );

      drawControl._toolbars.draw._modes.polygon.handler.disable();
    }
  }, [kmlAOI]);

  useEffect(() => {
    // console.error({ featureGroupRef });
    if (featureGroupRef.current) {
      const leafletGeoJSON = new L.GeoJSON({
        type: "FeatureCollection",
        // @ts-ignore
        features: features,
      });

      leafletGeoJSON.eachLayer((layer) => {
        featureGroupRef.current.addLayer(layer);
      });

      setLayer(leafletGeoJSON);
    }
  }, [featureGroupRef.current]);

  useEffect(() => {
    if (featureGroupRef.current) {
      // console.error({ featureGroupRef });
      if (step === "project_details") {
        featureGroupRef.current._map.fitBounds(
          featureGroupRef.current.getBounds()
        );
      }
    }
  }, [step, featureGroupRef.current]);

  const updateAOI = () => {
    const layers = featureGroupRef.current.getLayers();

    if (layers.length > 0) {
      // setFeatures(features);
      setMapFeatures({
        features: layers.map((layer) => layer.toGeoJSON()),
        type: "FeatureCollection",
      });
    }
  };

  const reset = () => {
    featureGroupRef.current.clearLayers();
    setFeatures([]);
    setActive(false);
  };

  const _onEdited = (e) => {
    setActive(false);
  };

  const _onCreated = (e) => {
    setActive(false);
  };

  const _onDeleted = (e) => {
    setActive(false);
  };

  const _onMounted = (drawControlHandler) => {
    setDrawControl(drawControlHandler);
    if (features.length === 0) {
      drawControlHandler._toolbars.draw._modes.polygon.handler.enable();
    }
  };

  const _onDrawStart = (e) => {
    setActive(true);
  };

  const generateToken = () => {
    var chars =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var token = "";
    for (var i = 0; i < 24; i++) {
      token += chars[Math.floor(Math.random() * chars.length)];
    }
    return token;
  };

  const onFilechange = (event) => {
    const file = event.target.files[0]; // get file
    const ext = getFileExtension(file);
    const reader = new FileReader();
    const size = file.size;

    if (size > 5000000) {
      alert("Maximum KML file size is 5 Megabytes");
      return false;
    }

    // on load file end, parse the text read
    reader.onloadend = (event) => {
      var text = event.target.result;
      if (ext === "kml") {
        parseTextAsKml(text);
      }
    };

    reader.readAsText(file); // start reading file
  };

  const parseTextAsKml = (text) => {
    const dom = new DOMParser().parseFromString(text, "text/xml"); // create xml dom object
    const converted = tj.kml(dom); // convert xml dom to geojson

    // @ts-ignore
    rewind(converted, false); // correct right hand rule
    setKmlAOI(converted); // save converted geojson to hook state
    // setStep("draw");
  };

  const getFileExtension = (file) => {
    const name = file.name;
    const lastDot = name.lastIndexOf(".");
    return name.substring(lastDot + 1);
  };

  const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    kmlFileRef.current.click();
  };
  return (
    <>
      <FeatureGroup ref={featureGroupRef}>
        <>
          <EditControl
            position="topright"
            onDrawStart={_onDrawStart}
            onEditStart={() => setActive(true)}
            onEdited={_onEdited}
            onDeleteStart={() => setActive(true)}
            onCreated={_onCreated}
            onDeleted={_onDeleted}
            onMounted={_onMounted}
            onDrawStop={() => setActive(false)}
            onEditStop={() => setActive(false)}
            draw={{
              polyline: false,
              rectangle: false,
              circle: false,
              marker: false,
              circlemarker: false,
              polygon: true,
            }}
          />
          {step === "draw" && (
            <>
              {" "}
              <div id="draw-message-box">
                {/* <h6 style={{ fontWeight: "bold" }}>{renderText()}</h6> */}
                <RenderText
                  count={featureGroupRef.current.getLayers().length}
                  active={active}
                />
                <hr />
                <Container>
                  <Row>
                    <Col>
                      <Button
                        disabled={active}
                        variant="secondary"
                        onClick={() => reset()}
                        style={{ width: "100%" }}
                      >
                        Reset
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        disabled={
                          active ||
                          featureGroupRef.current.getLayers().length === 0
                        }
                        onClick={() => updateAOI()}
                        style={{ width: "100%" }}
                      >
                        Calculate
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </div>
              <div id="draw-import-kml">
                <input
                  style={{ display: "none" }}
                  ref={kmlFileRef}
                  type="file"
                  id="kml_file"
                  name="kml"
                  accept=".kml"
                  onChange={onFilechange}
                />
                <Button
                  disabled={active}
                  variant="secondary"
                  onClick={() => onBtnClick()}
                >
                  Import KML
                </Button>
              </div>
            </>
          )}
        </>
      </FeatureGroup>
    </>
  );
};

const RenderText = ({ active, count }) => {
  // const count = featureGroupRef.current.getLayers().length;
  console.error("rendertext", { active, count });

  if (active && count > 0) {
    return <h6>Finish the active tool before continuning</h6>;
  }

  if (active && count === 0) {
    return <h6>At least one polygon must exist to calculate AOI</h6>;
  }

  if (count === 0) {
    return <h6>Draw Feature to Continue</h6>;
  } else {
    return (
      <h6>{`${count} feature${
        count > 1 ? "s" : ""
      } detected, Ready to Calculate AOI`}</h6>
    );
  }
};

export default EditFeature;
