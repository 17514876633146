import React, { useState, useEffect, useRef, useCallback } from "react";
import { Form, Dropdown, Table } from "react-bootstrap";
import { FormParams, ImageryAccuracy, ImageryClass } from "../Types/Types";
import ToolTip from "../Shared/ToolTip";

interface Props {
  formObj: FormParams;
  update: (arg1: string, arg2: boolean | string) => void;
  imagery_accuracies: ImageryAccuracy[];
  imagery_class: ImageryClass[];
}

const ImageryCollectionOrthorectified = ({
  formObj,
  update,
  imagery_accuracies,
  imagery_class,
}: Props) => {
  useEffect(() => {
    if (
      formObj.lidar_co_ca_cl_enabled &&
      formObj.image_co_or_bands === "RGBN"
    ) {
      update("image_co_or_bands", "RGB");
    }
  }, [formObj.lidar_co_ca_cl_enabled]);

  const handleChange = (e) => {
    update(e.target.name, e.target.value);
  };

  const renderAccuracy = (label) => {
    if (formObj.image_co_or_label) {
      const sel = imagery_accuracies.find((record) => record.name === label);

      switch (formObj.image_co_or_label) {
        case "24 in":
          return `(${sel.gsd_24_in})`;
        case "12 in":
          return `(${sel.gsd_12_in})`;
        case "6 in":
          return `(${sel.gsd_6_in})`;
        case "4 in":
          return `(${sel.gsd_4_in})`;
        case "3 in":
          return `(${sel.gsd_3_in})`;
        case "2 in":
          return `(${sel.gsd_2_in})`;
        case "1 in":
          return `(${sel.gsd_1_in})`;
        case "1 cm":
          return `(${sel.gsd_1cm})`;
        case "1 mm":
          return `(${sel.gsd_1mm})`;
        default:
          return "";
      }
    } else {
      return "";
    }
  };

  return (
    <div style={{ padding: "0.5em 0.25em 0.5em 0.5em" }}>
      <Form.Label htmlFor="image_co_or_label">GSD</Form.Label>
      <Form.Select
        id="image_co_or_label"
        name="image_co_or_label"
        onChange={handleChange}
        defaultValue={formObj.image_co_or_label}
      >
        <option></option>
        <option value="24 in">24 in</option>
        <option value="12 in">12 in</option>
        <option value="6 in">6 in</option>
        <option value="4 in">4 in</option>
        <option value="3 in">3 in</option>
        <option value="2 in">2 in</option>
        <option value="1 in">1 in</option>
        <option value="1 cm">1 cm</option>
        <option value="1 mm">1 mm</option>
      </Form.Select>

      <Form.Label htmlFor="image_co_or_accuracy_label">Accuracy</Form.Label>
      <Form.Select
        id="image_co_or_accuracy_label"
        name="image_co_or_accuracy_label"
        onChange={handleChange}
        defaultValue={formObj.image_co_or_accuracy_label}
      >
        <option></option>
        <option value="Reference">
          Reference {renderAccuracy("Reference")}
        </option>
        <option value="High">High {renderAccuracy("High")}</option>
        <option value="Highest">Highest {renderAccuracy("Highest")}</option>
      </Form.Select>

      <Form.Label htmlFor="image_co_or_grade">
        Imagery Grade Required
      </Form.Label>
      <ToolTip style={{ float: "right" }} title="Imagery Class">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Class</th>
              <th>Cloud Shadows</th>
              <th>Moisture Visible</th>
            </tr>
          </thead>
          <tbody>
            {imagery_class.map((record, index) => {
              return (
                <tr key={index}>
                  <td>{record.name}</td>
                  <td>{record.cloud_shadows}</td>
                  <td>{record.cloud_visibility}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </ToolTip>
      <Form.Select
        id="image_co_or_grade"
        name="image_co_or_grade"
        onChange={handleChange}
        defaultValue={formObj.image_co_or_grade}
      >
        <option></option>
        <option value="A">A</option>
        <option value="B">B</option>
        <option value="C">C</option>
        <option value="D">D</option>
        <option value="E">E</option>
      </Form.Select>

      <Form.Label htmlFor="image_co_or_sun_angle">
        Sun Angle Required
      </Form.Label>
      <Form.Select
        id="image_co_or_sun_angle"
        name="image_co_or_sun_angle"
        onChange={handleChange}
        defaultValue={formObj.image_co_or_sun_angle}
      >
        <option></option>
        <option value="Daylight">Daylight</option>
        <option value="20 Degrees">20 Degrees</option>
        <option value="25 Degrees">25 Degrees</option>
        <option value="30 Degrees">30 Degrees</option>
        <option value="45 Degrees">45 Degrees</option>
      </Form.Select>

      <Form.Label htmlFor="image_co_or_bands">Bands</Form.Label>
      <ToolTip
        style={{ float: "right" }}
        title="Bands"
        message="If imagery and LiDAR are selected in the same quote, Imagery may be limited to 3-Band RGB (natural color) output. If imagery is selected alone, ASI may be able to provide 4-band imagery including NIR"
      />
      <Form.Select
        id="image_co_or_bands"
        name="image_co_or_bands"
        onChange={handleChange}
        defaultValue={formObj.image_co_or_bands}
      >
        <option value="RGB">RGB</option>
        {!formObj.lidar_co_ca_cl_enabled && <option value="RGBN">RGBN</option>}
      </Form.Select>

      <Form.Check
        style={{ margin: "0.5em 0" }}
        type={"checkbox"}
        defaultChecked={formObj.image_co_or_eo_and_raw_imagery_only}
        id="image_co_or_eo_and_raw_imagery_only"
        name="image_co_or_eo_and_raw_imagery_only"
        label={"EO & Raw Imagery Only"}
        onChange={(e) => update(e.target.name, e.target.checked ? true : false)}
      />
    </div>
  );
};

export default ImageryCollectionOrthorectified;
