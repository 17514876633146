import React, { useRef } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { FormParams } from "../Types/Types";

interface Response {
  token: string;
  message: string;
  pass: boolean;
  total: number;
}

interface Props {
  formObj: FormParams;
  response: Response;
  setStep: (arg0: string) => void;
  host: string;
}

const OutputModal = ({ response, setStep, host, formObj }: Props) => {
  // console.log({ response, setStep, host, formObj });

  const renderEmail = () => {
    let subject = `Project: ${formObj.project_name}`;
    let body = `
      <a href="${host}/estimate/${response.token}">View Estiamte Report</a>
    `;

    window.open(
      "mailto:sales@aerialservicesinc.com?body=" +
        encodeURIComponent(body) +
        "&subject=" +
        encodeURIComponent(subject)
    );
  };

  return (
    <Modal
      centered
      show={true}
      animation={false}
      backdrop={"static"}
      keyboard={false}
      size="lg"
    >
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Title>Project Price</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "left" }}>
        <Container>
          <h2 style={{ textAlign: "center", margin: ".5em 0 1em" }}>
            $
            {response.total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </h2>

          <Container style={{ maxWidth: "90%" }}>
            <Row style={{ margin: "0.5em 0" }}>
              <Col>
                <Button
                  variant="secondary"
                  onClick={() => setStep("draw")}
                  style={{ width: "100%" }}
                >
                  Adjust AOI
                </Button>
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  onClick={() => setStep("additional_info")}
                  style={{ width: "100%" }}
                >
                  Adjust Quote Details
                </Button>
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  onClick={() => setStep("project_details")}
                  style={{ width: "100%" }}
                >
                  Adjust Project Details
                </Button>
              </Col>
            </Row>
            <Row style={{ margin: "0.5em 0" }}>
              <Col>
                <Button
                  variant="primary"
                  href={`/estimate/${response.token}`}
                  target="_blank"
                  style={{ width: "100%" }}
                >
                  Print Quote for Signature
                </Button>
              </Col>
            </Row>
            <Row style={{ margin: "0.5em 0 2em" }}>
              <Col>
                <Button
                  variant="primary"
                  onClick={() => renderEmail()}
                  style={{ width: "100%" }}
                >
                  Contact me to begin this Project
                </Button>

                <p
                  style={{
                    textAlign: "center",
                    marginTop: "10px",
                    fontStyle: "italic",
                    fontSize: "0.9rem",
                  }}
                >
                  Quote ID: {response.token}
                </p>
              </Col>
            </Row>
          </Container>
        </Container>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={() => setResponse(null)}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default OutputModal;
