import React from "react";

import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import "bootstrap-icons/font/bootstrap-icons.css";

interface Props {
  title: string;
  message?: string;
  style?: any;
  children?: React.ReactNode
}

const ToolTip = ({ title, message, children, style = {} }: Props) => {
  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom-end"
      rootClose
      overlay={
        <Popover id="popover-basic">
          <Popover.Header as="h3">{title}</Popover.Header>
          <Popover.Body>{children ? children : message}</Popover.Body>
        </Popover>
      }
    >
      <i className="flex-none bi bi-info-square" style={style}></i>
    </OverlayTrigger>
  );
};

export default ToolTip;
